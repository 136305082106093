import Plyr from "plyr/dist/plyr.polyfilled";

const heroVideos = document.querySelectorAll(".hero-header video");

Plyr.setup(heroVideos, {
	controls: [],
	loadSprite: false,
	clickToPlay: false,
	hideControls: true,
	autoplay: true,
	muted: true,
	loop: { active: true },
	storage: { enabled: false },
});
