import { enablePageScroll } from "scroll-lock";

(function ($) {
	// define element to be animated
	const $root = $("html, body");

	// prevent page to jump to anchor in onLoad scrollTo case
	let target = window.location.hash; // store hash from url to a variable

	target = target.replace("#", ""); // remove hash character from variable
	//window.location.hash = ""; // remove hash from url

	const anchorLinks = document.querySelectorAll('a[href*="#"]');
	const scrollToNavigation = (event) => {
		document
			.querySelector(".navigation-overlay")
			.classList.remove("active");
		document
			.querySelector(".hamburger")
			.classList.remove("menu-toggle--active", "is-active");
		enablePageScroll(document.body);
	};
	if (anchorLinks.length) {
		anchorLinks.forEach((anchorLink) =>
			anchorLink.addEventListener("click", scrollToNavigation)
		);
	}

	// on click scroll to id defined in the clicked element
	$('a[href*="#"]').click(function (e) {
		const hash = e.currentTarget.href.split("#");
		if (hash[1] !== "") {
			const elToScroll = document.querySelector("#" + hash[1]);
			if (!!elToScroll) {
				e.preventDefault();
				$root.animate(
					{
						scrollTop:
							elToScroll.offsetTop - $(".header").height() - 120,
					},
					600
				);
				window.location.hash = "#" + hash[1];
				return false;
			}
		}
	});

	// on page load scroll to anchor defined in url hash
	$(document).ready(function () {
		if (window.location.hash !== "") {
			$root.animate(
				{
					scrollTop:
						$("#" + target).offset().top -
						$(".header").height() -
						120,
				},
				600
			);
			window.location.hash = "#" + target; // add the hash back to url in case it should be needed for other functions
			return false;
		}
	});
})(jQuery);
