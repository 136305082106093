(function ($) {
	const intro = document.querySelector(".contest__wrapper--intro");
	const form = document.querySelector(".contest__wrapper--form");
	const introStart = document.querySelector(".contest__wrapper--intro__start");
	const formCancel = document.querySelector(".contest__wrapper--form__cancel");
	var userValue = '';

	if (intro) {
		introStart.addEventListener("click", function () {
			intro.classList.add("contest__wrapper--hidden");
			form.classList.remove("contest__wrapper--hidden");
		});
		formCancel.addEventListener("click", function () {
			window.location.reload();
		});
	}

	if (form) {
		const gravityId = form.querySelector('form').getAttribute('data-formid');
		$(document).on('gform_post_render', function(event, formId, currentPage){
			if (formId == gravityId) {
				const genderField = form.querySelector('.gfield.gender');
				const salutationField = genderField.nextElementSibling;
				genderField.querySelectorAll('input').forEach(function (input) {
					input.addEventListener('change', function () {
						salutationField.querySelector('input').value = genderField.querySelector('input:checked').value === 'f' ? 'Frau' : 'Herr';
					});
				});
			}
		});
	}

	const contestLeaderboard = document.querySelector(".contest-leaderboard-data");
	if (contestLeaderboard) {
		const location = contestLeaderboard.getAttribute("data-location");
		const form = contestLeaderboard.getAttribute("data-form");

		setInterval(function () {
			$.ajax({
				type: 'GET',
				dataType: 'json',
				url: wkScriptVars.ajax_url,
				data: `action=contest-leaderboard&location=${location}&form=${form}`,
				success(res) {
					contestLeaderboard.innerHTML = res.data.html;
				},
			});
		}, 10000);
	}

	jQuery(document).on('gform_post_render', function(event, form_id, current_page){
		if (form_id == 4) {
			const userNameField = document.querySelector('#input_4_4');
			const userNameDisplayField = document.querySelector('#field_4_13');

			if (userNameField && userNameDisplayField) {
				userNameDisplayField.innerHTML += `<h3 class="text-center">${userNameField.value}</h3>`;
			}
		}
    });
})(window.jQuery);
