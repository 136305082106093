import Swiper, {Navigation, A11y} from 'swiper';

const options = {
	modules: [Navigation, A11y],
	speed: 500,
	loop: true,
	spaceBetween: 15,
	slidesPerView: 1,
	a11y: {
		enabled: true,
		nextSlideMessage: window.wkScriptVars.next_slide,
		prevSlideMessage: window.wkScriptVars.previous_slide,
	},
};


const ambassadors = document.querySelectorAll('.ambassador');

if (ambassadors.length) {
	ambassadors.forEach(ambassador => {
		let swiper = ambassador.querySelector('.ambassador-slider__container');

		if(swiper){
		new Swiper(
			swiper,
			{
				...options,
				navigation: {
					nextEl: ambassador.querySelector('.swiper-button--next'),
					prevEl: ambassador.querySelector('.swiper-button--prev'),
				},
			});
		}
	});
}
