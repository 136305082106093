(($) => {
	let raf =
		window.requestAnimationFrame ||
		window.webkitRequestAnimationFrame ||
		window.mozRequestAnimationFrame ||
		window.msRequestAnimationFrame ||
		window.oRequestAnimationFrame;
	let $window = $(window);
	let lastScrollTop = $window.scrollTop();
	let lastScrollTopLoop = $window.scrollTop();
	let $bodyElement = null;
	let didScroll = false;
	let homePage = false;
	let delta = 5;
	let waiting = false,
		endScrollHandle;

	const scroll = () => {
		didScroll = true;
		scrollOffset = $(window).scrollTop();
		animationTrigger = $(window).height() * 0.5;

		if (homePage) {
			let homeAnimationTrigger = $(".hero-header").height();
			if ($(window).width() < 640) {
				if (scrollOffset > $("header.header").height()) {
					$("body").addClass("passed-intro-element");
				} else {
					$("body").removeClass("passed-intro-element");
				}
			} else {
				if (scrollOffset > homeAnimationTrigger - $("header.header").height()) {
					$("body").addClass("passed-intro-element");
				} else {
					$("body").removeClass("passed-intro-element");
				}
			}
		}
	};

	const loop = () => {
		let scrollTop = $window.scrollTop();
		if (lastScrollTopLoop === scrollTop) {
			raf(loop);
			return;
		} else {
			lastScrollTopLoop = scrollTop;

			// fire scroll function if scrolls vertically
			scroll();
			raf(loop);
		}
	};

	if (raf) {
		loop();
	}

	const hasScrolled = function () {
		let st = $(this).scrollTop();

		if (Math.abs(lastScrollTop - st) <= delta) {
			return;
		}
	};

	// run hasScrolled() and reset didScroll status
	setInterval(() => {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
		lastScrollTop = $window.scrollTop();
	}, 250);

	$(document).on("ready", () => {
		if ($bodyElement === null) {
			$bodyElement = $("body");
		}

		if ($("body").hasClass("home")) {
			homePage = true;
		}

		scroll();
	});
})(window.jQuery);
