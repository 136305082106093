(($) => {
	let sections = null;
	let decorations = null;
	let lastScrollTop = null;
	let color = null;
	sections = document.querySelectorAll(".product-teaser");
	decorations = document.querySelector(".product-teasers__backgrounds");
	if (!!sections !== true) {
		return;
	}
	function getViewPercentage(element) {
		const viewport = {
			top: window.pageYOffset,
			bottom: window.pageYOffset + window.innerHeight,
		};

		const elementBoundingRect = element.getBoundingClientRect();
		const elementPos = {
			top: elementBoundingRect.y + window.pageYOffset,
			bottom:
				elementBoundingRect.y +
				elementBoundingRect.height +
				window.pageYOffset,
		};

		if (
			viewport.top > elementPos.bottom ||
			viewport.bottom < elementPos.top
		) {
			return 0;
		}

		// Element is fully within viewport
		if (
			viewport.top < elementPos.top &&
			viewport.bottom > elementPos.bottom
		) {
			return 100;
		}

		// Element is bigger than the viewport
		if (
			elementPos.top < viewport.top &&
			elementPos.bottom > viewport.bottom
		) {
			return 100;
		}

		const elementHeight = elementBoundingRect.height;
		let elementHeightInView = elementHeight;

		if (elementPos.top < viewport.top) {
			elementHeightInView =
				elementHeight - (window.pageYOffset - elementPos.top);
		}

		if (elementPos.bottom > viewport.bottom) {
			elementHeightInView =
				elementHeightInView - (elementPos.bottom - viewport.bottom);
		}

		const percentageInView =
			(elementHeightInView / window.innerHeight) * 100;

		return Math.round(percentageInView / 100);
	}
	const scroll = (scrollTop) => {
		if (scrollTop < 0) {
			scrollTop = 0;
		}
		// fade to blue and out
		let opacity = null;

		sections.forEach((section) => {
			color = section.getAttribute("data-color");
			opacity = getViewPercentage(section);
			decorations.querySelector(`[data-color="${color}"]`).style.opacity =
				opacity;
		});
	};

	const raf =
		window.requestAnimationFrame ||
		window.webkitRequestAnimationFrame ||
		window.mozRequestAnimationFrame ||
		window.msRequestAnimationFrame ||
		window.oRequestAnimationFrame;

	let loop = () => {
		const scrollTop = window.scrollY;

		if (lastScrollTop === scrollTop) {
			raf(loop);
			return;
		} else {
			lastScrollTop = scrollTop;
			// fire scroll function if scrolls vertically
			scroll(scrollTop);
			raf(loop);
		}
	};
	if (raf) {
		loop();
	}
})(window.jQuery);
