import Rellax from "rellax";
import ScrollReveal from "scrollreveal";
const sr = new ScrollReveal();

const pageDecorations = document.querySelector(".page-decoration");
if (pageDecorations) {
	var rellax = new Rellax(".page-decoration", {
		center: true,
		round: false,
		vertical: true,
		horizontal: false,
	});

	sr.reveal(".page-decoration__image", {
		distance: "0px",
		delay: 200,
		viewFactor: 2,
		container: "div.content-wrapper",
	});
}

sr.reveal(".product__illustration--teaser", {
	distance: "32px",
	delay: 200,
	origin: "right",
	duration: 500,
	viewFactor: 0.8,
	scale: 0.8,
	reset: true,
	rotate: {
		x: 0,
		y: 0,
		z: -40,
	},
});

sr.reveal(".product__image--teaser", {
	distance: "32px",
	delay: 100,
	interval: 500,
	origin: "left",
	duration: 500,
	viewFactor: 0.5,
});
