// manifest
import "pwacompat/src/pwacompat";
// add better focus and input type detection on body element
import "what-input";

// components
import AucorNavigation from "./components/aucorNavigation";
import "./components/pageDecorations";
import "./components/productBackground";
import "./components/headerAnimation";
import "./components/accordion";
import "./components/smoothScroll";
import "./components/videoSettings";
import "./components/sliders";
import "./components/contest";

// init navigation
const primaryNavigation = document.getElementById("primary-navigation");

if (!!primaryNavigation) {
	// init navigation
	AucorNavigation(primaryNavigation, {
		desktopMinWidth: 932, // min width in pixels
		menuToggle: ".js-menu-toggle", // selector for toggle
	});
}
