import "beefup/dist/js/jquery.beefup.min";

(function ($) {
	const $beefup = $(".accordion__item").beefup({
		openSingle: false,
		scroll: false,
		trigger: ".accordion__header",
		content: ".accordion__body",
	});
})(window.jQuery);
